<template>
  <div class="main clearfix">
    <div class="box">
      <div class="news-wrap">
      <div class="news-title">
        <h1 v-html="title"></h1>
      </div>
      <div class="news-content" v-html="content"></div>
    </div>
    </div>
  </div>
</template>

<script>

import { toRefs } from "vue";

export default {
  name: "DetailsContainers",
  props: ["title", "content"],
  components: {},
  created() {},
  setup(props) {
    return { ...toRefs(props) };
  },
  methods: {},
};
</script>

<style scoped>
</style>