<template>
  <!-- <div class="m_nav">
      <div class="m_nav_box">
          <ul class="nav-box">
              <li><router-link to="/about/contact">联系我们</router-link></li>
              <li><router-link to="/about/introduce">单位简介</router-link></li>
          </ul>
      </div>
  </div> -->
  <DetailsContainers :title="title" :content="content" />
</template>

<script>
import DetailsContainers from "../layout/DetailsContainers";
import { onMounted, reactive, toRefs } from "vue";
import axios from "@/api/apis";

export default {
  name: "Contact",
  components: { DetailsContainers },
  setup() {
    const state = reactive({
      // title: "联系我们",
      content: "",
    });
    const loadAboutContact = () => {
      axios
      
        // .post("/about/contact")

        .post("https://cqgdrm.com/api/website/about/contact")
        .then(function (data) {
          state.title = data.title;
          state.content = data.content;
        })
        .catch(function (error) {
          console.log(error);
        });
    };
    onMounted(() => {
      loadAboutContact();
    });
    return {
      ...toRefs(state),
    };
  },
};
</script>

<style scoped>
</style>