<template>
  <div class="main clearfix">
    <CurrentLocation />
    <Introduce />
    <Contact />
  
  </div>
</template>


<script>
import CurrentLocation from "../layout/components/CurrentLocation";
import Introduce from "./Introduce";
import Contact from "./Contact";
export default {
  name: "About",
  components: {Introduce,Contact,CurrentLocation},
  created() {},
  setup() {},
  methods: {},
};
</script>

<style scoped>
</style>
